export { getStaticProps } from "lib/pages/getStaticPropsUser";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuList,
  Select,
  Text,
} from "@chakra-ui/react";
import { AccountStatementStatus, LedgerSubhead } from "@obtainly-v2/enums";
import {
  AccountStatementTable,
  CreditBalanceCard,
  PayoutAccountCard,
  VirtualAccountCard,
} from "components/account";
import { BusinessProfileCTA } from "components/business";
import { MonthYearPicker } from "components/common";
import { Card } from "components/common/Card";
import { Layout } from "components/layouts/Layout";
import { LoanRequestCTA } from "components/loan-request";
import { useProfileCompletion } from "hooks";
import { accountFilterState } from "hooks/useAccountStatementFilter";
import { useAuth } from "hooks/useAuth";
import { useAtom } from "jotai";
import type { NextPage } from "next";
import { useState } from "react";
import { FiFilter } from "react-icons/fi";
import { getAccountStatementStatus } from "utils";

export interface FilterProps {
  status: LedgerSubhead;
  month: number;
}

const DashboardPage: NextPage = () => {
  const {
    auth: { user },
  } = useAuth();

  const DEFAULT_FILTER = "All";
  const [subhead, setSubhead] = useState<AccountStatementStatus>(
    AccountStatementStatus.All
  );

  const [{ month }, setAccountFilterState] = useAtom(accountFilterState);
  const { isComplete } = useProfileCompletion(user?.business);

  return (
    <Layout>
      {!!user && (
        <Grid
          gridGap="20px"
          gridTemplateColumns={["1fr", "1fr", "1fr", "2fr 1fr"]}
        >
          <GridItem order={[1, 1, 1, 0]} overflow="auto">
            <Grid gridGap={["10px", "20px"]}>
              <CreditBalanceCard businessId={user?.businessId!} />
              {!!user?.business && (
                <>
                  <VirtualAccountCard business={user?.business} user={user!} />
                  <PayoutAccountCard user={user} onlyActive />
                </>
              )}
            </Grid>

            <Box mt="20px">
              <Card mt="5px">
                <Flex
                  py="14px"
                  pl="20px"
                  pr="12px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text color="gray.700" fontWeight="medium">
                    Account Statement
                  </Text>

                  <Menu>
                    <MenuButton
                      as={Button}
                      aria-label="Options"
                      leftIcon={<FiFilter />}
                      variant="outline"
                    >
                      Filter
                    </MenuButton>
                    <MenuList px="1rem" py="1.5rem">
                      <Text fontSize="0.75rem" mb="0.5rem">
                        By Payment Types
                      </Text>
                      <Select
                        size="md"
                        onChange={(event) =>
                          setSubhead(
                            event.currentTarget.value as AccountStatementStatus
                          )
                        }
                      >
                        {Object.values(AccountStatementStatus).map((item) => (
                          <option key={item} value={item}>
                            {item === ""
                              ? DEFAULT_FILTER
                              : getAccountStatementStatus(item)}
                          </option>
                        ))}
                      </Select>

                      <Divider py="0.5rem" />

                      <Text fontSize="0.75rem" my="0.5rem">
                        By Date
                      </Text>

                      <MonthYearPicker
                        size="sm"
                        value={month as any}
                        onChange={(newDate) => {
                          setAccountFilterState((prev) => ({
                            ...prev,
                            month: newDate,
                          }));
                        }}
                      />
                    </MenuList>
                  </Menu>
                </Flex>
                <AccountStatementTable
                  filter={subhead}
                  businessId={user?.businessId!}
                />
              </Card>
            </Box>
          </GridItem>
          <GridItem order={[0, 0, 0, 1]}>
            {!isComplete && <BusinessProfileCTA />}
            <LoanRequestCTA businessId={user?.businessId!} user={user} />
          </GridItem>
        </Grid>
      )}
    </Layout>
  );
};

export default DashboardPage;
